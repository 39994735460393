import { useState } from "react";
import { db } from "../firebase";
import { doc, getDoc, collection, addDoc, updateDoc } from "firebase/firestore";

export default function Upload() {
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [seoTitle, setSeoTitle] = useState("");
  const [seoDescription, setSeoDescription] = useState("");
  const [seoAltTag, setSeoAltTag] = useState("");
  const [items, setItems] = useState([
    { photo: "", description: "", link: "" }, // Initial item
  ]);
  const [collectionName, setCollectionName] = useState("");

  const addDocument = async () => {
    try {
      const formattedItems = items.map((item) => ({
        photo: item.photo,
        description: item.description,
        link: item.link,
      }));

      // Add document to Firestore collection
      await addDoc(collection(db, collectionName), {
        title,
        url,
        seoTitle,
        seoDescription,
        seoAltTag,
        items: formattedItems,
      });

      updateDocument(collectionName);

      // Clear form fields after submission
      setTitle("");
      setUrl("");
      setItems([{ photo: "", description: "", link: "" }]);
      setSeoTitle("");
      setSeoDescription("");
      setSeoAltTag("");
      setCollectionName("");
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const updateDocument = async (nameToUpdate) => {
    console.log("hello");
    try {
      const summaryDocRef = doc(db, "categories", "summary");
      const summaryDoc = await getDoc(summaryDocRef);

      if (summaryDoc.exists()) {
        console.log("exists");
        // Step 3: Find the index of the "decor" entry in the collections array
        const collections = summaryDoc.data().collections;
        const collectionIndex = collections.findIndex(
          (collection) => collection.collectionId === nameToUpdate
        );

        console.log(collectionIndex);
        if (collectionIndex !== -1) {
          // Step 4: Increase the count by 1
          collections[collectionIndex].documentCount += 1;

          // Step 5: Update the summary document with the new collections array
          await updateDoc(summaryDocRef, { collections });

          console.log("Summary document successfully updated!");
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const addItem = () => {
    setItems([...items, { photo: "", description: "", link: "" }]);
  };

  const handleItemChange = (index, event) => {
    const { name, value } = event.target;
    const updatedItems = [...items];
    updatedItems[index][name] = value;
    setItems(updatedItems);
  };

  return (
    <div className="library">
      <h1>Upload</h1>
      <div className="mt-16">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            addDocument();
          }}
        >
          <div>
            <p>Title</p>
            <input
              type="text"
              className="input"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="mt-16">
            <p>Thumbnail url</p>
            <input
              type="text"
              className="input"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </div>
          <div className="mt-16">
            <p>Shopping List</p>
            {items.map((item, index) => (
              <div key={index} className="mt-16 shopping-list">
                <p>Photo</p>
                <input
                  type="text"
                  className="input"
                  name="photo"
                  value={item.photo}
                  onChange={(e) => handleItemChange(index, e)}
                />

                <p className="mt-16">Description</p>
                <input
                  type="text"
                  className="input"
                  name="description"
                  value={item.description}
                  onChange={(e) => handleItemChange(index, e)}
                />

                <p className="mt-16">Link</p>
                <input
                  type="text"
                  name="link"
                  className="input"
                  value={item.link}
                  onChange={(e) => handleItemChange(index, e)}
                />
                <div>
                  {index === items.length - 1 && (
                    <button
                      type="button"
                      className="mt-16 outline-btn"
                      onClick={addItem}
                    >
                      Add More
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="mt-16">
            <p>SEO title</p>
            <input
              type="text"
              className="input"
              value={seoTitle}
              onChange={(e) => setSeoTitle(e.target.value)}
            />
          </div>
          <div className="mt-16">
            <p>SEO description</p>
            <input
              type="text"
              className="input"
              value={seoDescription}
              onChange={(e) => setSeoDescription(e.target.value)}
            />
          </div>
          <div className="mt-16">
            <p>SEO Alt tag</p>
            <input
              type="text"
              className="input"
              value={seoAltTag}
              onChange={(e) => setSeoAltTag(e.target.value)}
            />
          </div>
          <div className="mt-16">
            <p>Collection</p>
            <input
              type="text"
              className="input"
              value={collectionName}
              onChange={(e) => setCollectionName(e.target.value)}
            />
          </div>
          <button type="submit" className="mt-16 primary-btn">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}
