import {
  collection,
  getDocs,
  getDoc,
  doc,
  writeBatch,
} from "firebase/firestore";
import { db } from "./firebase";

export async function renameCollection(oldCollectionName, newCollectionName) {
  try {
    const oldCollectionRef = collection(db, oldCollectionName);
    const newCollectionRef = collection(db, newCollectionName);

    const snapshot = await getDocs(oldCollectionRef);
    const batch = writeBatch(db);

    snapshot.forEach((documentSnapshot) => {
      const newDocRef = doc(newCollectionRef, documentSnapshot.id);
      batch.set(newDocRef, documentSnapshot.data());
      const oldDocRef = doc(oldCollectionRef, documentSnapshot.id);
      batch.delete(oldDocRef);
    });

    await batch.commit();
    console.log("Collection renamed successfully");
  } catch (error) {
    console.error("Error renaming collection: ", error);
  }
}

export async function readCategories() {
  try {
    // Reference to the summary document
    const summaryRef = doc(db, "categories", "summary");
    const summarySnapshot = await getDoc(summaryRef);

    if (!summarySnapshot.exists()) {
      throw new Error("No such document!");
    }

    // Extract the collections array
    const categoryData = summarySnapshot.data();
    let collections = categoryData.collections || [];

    // Sort collections by documentCount in descending order
    collections.sort((a, b) => b.documentCount - a.documentCount);

    return collections;
  } catch (error) {
    console.error("Error fetching categories:", error);
    return [];
  }
}
