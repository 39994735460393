import { faHeart } from "@fortawesome/free-regular-svg-icons";
import {
  faBars,
  faBorderAll,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { faFire } from "@fortawesome/free-solid-svg-icons/faFire";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import GlobalContext from "./context/GlobalContext";
import logo from "../logo.png";

export default function Nav({ categories }) {
  const { navActive, setNavActive } = useContext(GlobalContext);
  const { setActiveCollection } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [mobile, setMobile] = useState(false);

  const handleDisplayCollection = (collectionName, collectionId) => {
    setNavActive(collectionName);
    setActiveCollection(collectionId);
    const formatTitle = collectionName.toLowerCase().replace(/\s+/g, "-");
    navigate(`/${formatTitle}`);
  };

  return (
    <div className={`nav ${mobile ? "nav-mobile" : ""}`}>
      <div className="flex space-between nav-btn">
        <div className="nav-logo">
          <div>
            <img src={logo} width="32px" alt="DIY Wedding Library logo" />
          </div>
          <div>DIYWeddings</div>
        </div>
        <button className="icon-btn" onClick={() => setMobile(!mobile)}>
          <FontAwesomeIcon icon={!mobile ? faBars : faClose} size="lg" />
        </button>
      </div>
      <div className={`nav-content ${mobile ? "nav-mobile-content" : ""}`}>
        <div className={`nav-logo ${mobile ? "mobile-hide-logo" : ""}`}>
          <div>
            <img src={logo} width="32px" alt="DIY Wedding Library logo" />
          </div>
          <div>DIY Wedding</div>
        </div>
        <div className={`${mobile ? "mobile-menu-margin" : ""}`}>
          <div className="menu-label">Browse by</div>
          <Link to="/">
            <div
              className={`menu-item ${
                navActive === "Popular" ? "menu-active" : ""
              }`}
              onClick={() => handleDisplayCollection("Popular", "popular")}
            >
              <FontAwesomeIcon icon={faHeart} />
              Editor's Pick
            </div>
          </Link>
          <div
            className={`menu-item ${
              navActive === "Trending" ? "menu-active" : ""
            }`}
            onClick={() => handleDisplayCollection("Trending", "trending")}
          >
            <FontAwesomeIcon icon={faFire} />
            Trending
          </div>
          <div
            className={`menu-item ${navActive === "Kits" ? "menu-active" : ""}`}
            onClick={() => handleDisplayCollection("Kits", "kits")}
          >
            <FontAwesomeIcon icon={faBorderAll} />
            Kits
          </div>
          <div className="menu-label mt-16">All categories</div>
          {categories.map(
            (category) =>
              // Skip browse by categories
              category.collectionName !== "Categories" &&
              category.collectionName !== "Popular" && (
                <div
                  className={`menu-item space-between ${
                    navActive === category.collectionName ? "menu-active" : ""
                  }`}
                  key={category.collectionId}
                  onClick={() =>
                    handleDisplayCollection(
                      category.collectionName,
                      category.collectionId
                    )
                  }
                >
                  <div>{category.collectionName}</div>
                  <div className="menu-counter">{category.documentCount}</div>
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
}
