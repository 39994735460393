import "./App.css";
import "@fontsource-variable/fredoka";
import Nav from "./components/Nav";
import Library from "./components/Library";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Upload from "./components/Upload";
import { useState, useEffect } from "react";
import DetailedView from "./components/DetailedView";
import { readCategories } from "./utils";
import GlobalState from "./components/context/GlobalState";

function App() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      const categoriesData = await readCategories();
      setCategories(categoriesData);
    };

    fetchCategories();
  }, []);

  return (
    <Router>
      <GlobalState>
        {categories.length !== 0 && (
          <div className="app">
            <div className="nav-container">
              <Nav categories={categories} />
            </div>
            <div className="content-container">
              <Routes>
                <Route path="/" element={<Library categories={categories} />} />
                <Route
                  path="/:collection"
                  element={<Library categories={categories} />}
                />
                <Route path="/upload" element={<Upload />} />
                <Route path="/:collection/:title" element={<DetailedView />} />
              </Routes>
            </div>
          </div>
        )}
      </GlobalState>
    </Router>
  );
}

export default App;
