import {
  faHeart,
  faShareFromSquare,
} from "@fortawesome/free-regular-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid } from "@mui/material";
import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import GlobalContext from "./context/GlobalContext";

export default function DetailedView() {
  const location = useLocation();
  const { item } = location.state || {};
  const { activeCollection } = useContext(GlobalContext);
  const formatCollection = activeCollection.replace(/-/g, " ");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>{item.seoTitle}</title>
        <meta name="description" content={item.seoDescription} />
      </Helmet>
      <div className="library">
        <div className="w50">
          <Link to="/">
            <button className="text-btn">
              <FontAwesomeIcon icon={faArrowLeft} />
              Back to {formatCollection}
            </button>
          </Link>
          <h1 className="mt-8">{item.title}</h1>
        </div>
        <div className="mt-24">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={0}>
              <Grid item xs={12} md={6}>
                <div>
                  <img
                    src={item.url}
                    alt={item.seoAltTag}
                    className="detail-thumbnail"
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="mr-16">
                  <div className="button-group">
                    <button className="primary-btn full-width-btn">
                      <FontAwesomeIcon icon={faHeart} />
                      Save post
                      <div className="badge">Go pro</div>
                    </button>
                    <button className="secondary-btn">
                      <FontAwesomeIcon icon={faShareFromSquare} />
                    </button>
                  </div>
                  <h2 className="mt-24">Do it yourself</h2>
                  <div className="mt-24">
                    <Grid container spacing={2}>
                      {item.items.map((subItem, index) => (
                        <Grid item xs={12} sm={6} key={index}>
                          <div
                            className="item-card"
                            onClick={() => window.open(subItem.link, "_blank")}
                          >
                            <img
                              src={subItem.photo}
                              alt={subItem.description}
                              className="item-thumbnail"
                            />
                            <p>{subItem.description}</p>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </div>
  );
}
