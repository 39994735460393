import Masonry from "react-masonry-css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import GlobalContext from "./context/GlobalContext";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";

export default function Library({ categories }) {
  const { activeCollection } = useContext(GlobalContext);
  const navigate = useNavigate();

  // variables for categories
  const { retrievedCollections, setRetrievedCollections } =
    useContext(GlobalContext);
  const [showCollection, setShowCollection] = useState();
  const [activeCategory, setActiveCategory] = useState();
  // empty states
  const [showEmpty, setShowEmpty] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const showActiveCollection = async () => {
      if (activeCollection === "trending" || activeCollection === "kits") {
        setShowEmpty(true);
      } else if (retrievedCollections[activeCollection]) {
        setShowEmpty(false);
        fetchActiveCategory(activeCollection);
        setShowCollection(retrievedCollections[activeCollection]);
      } else {
        setShowEmpty(false);
        fetchCollection(activeCollection);
        fetchActiveCategory(activeCollection);
      }
    };
    showActiveCollection();
  }, [activeCollection]);

  const fetchCollection = async (collectionId) => {
    try {
      const querySnapshot = await getDocs(collection(db, collectionId));
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push({ id: doc.id, ...doc.data() });
      });
      setShowCollection(items);
      // updating retrieved collections
      setRetrievedCollections((prevCollections) => ({
        ...prevCollections,
        [collectionId]: [...(prevCollections[collectionId] || []), ...items],
      }));
    } catch (error) {
      console.error("Error fetching popular items: ", error);
    }
  };

  const fetchActiveCategory = (activeCollection) => {
    const foundCategory = categories.find(
      (category) => category.collectionId === activeCollection
    );
    setActiveCategory(foundCategory);
  };

  const viewItem = (item) => {
    const formatTitle = item.title.replace(/\s+/g, "-");
    const formatCollection = activeCollection
      .toLowerCase()
      .replace(/\s+/g, "-");
    navigate(`/${formatCollection}/${formatTitle}`, { state: { item } });
  };

  const displayCategoryContent = (name) => {
    if (name === "Popular") {
      return "Free DIY Wedding Library";
    } else if (name && name.toLowerCase().startsWith("wedding")) {
      return name;
    } else {
      return `Wedding ${name}`;
    }
  };

  return showEmpty ? (
    <div className="empty-state">
      <img
        alt="a cute cartoon of a bridezilla"
        src="https://media0.giphy.com/media/v1.Y2lkPTc5MGI3NjExdHBzanVvaGxnanJja2lycjM1cHIzenl1YWszdXB5Mzc3b2djcGptMiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/26u4f85953yBzGcve/giphy.webp"
        width="30%"
      />
      <div className="w40">
        <h1>Oops! Coming soon.</h1>
        <p className="mt-8">
          We are in the middle of DIY-ing our own wedding. We'll be back with
          this as soon as we can.
        </p>
      </div>
    </div>
  ) : (
    showCollection && (
      <div className="library">
        <div className="w50">
          <h1>{displayCategoryContent(activeCategory.collectionName)}</h1>
          <h3 className="paragraph-height mt-16">
            {activeCategory.collectionDescription}
          </h3>
        </div>
        <Masonry
          breakpointCols={{ default: 4, 1100: 3, 700: 2, 500: 1 }}
          className="my-masonry-grid mt-32"
          columnClassName="my-masonry-grid_column"
        >
          {showCollection.map((item, index) => (
            <div
              key={index}
              className="grid-item"
              onClick={() => viewItem(item)}
            >
              <img
                src={item.url}
                alt={item.seoAltTag}
                className="grid-thumbnail"
              />
            </div>
          ))}
        </Masonry>
      </div>
    )
  );
}
