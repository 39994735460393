import { useState } from "react";
import GlobalContext from "./GlobalContext";

// const testPopular = [
//   {
//     title: "Polaroid Guest Book Table",
//     url: "https://i.pinimg.com/564x/0a/0f/42/0a0f42e4ab5edae8e2ed812f5f55a5ed.jpg",
//     items: [
//       {
//         description: "Canva template",
//         link: "https://www.canva.com/p/templates/EAFkYdPzcyk-couple-photo-collage-simple-wedding-invitation/",
//         photo:
//           "https://i.pcmag.com/imagery/reviews/05GF8sMpHfawiyKgGnrgf7X-8.fit_scale.size_760x427.v1665503374.jpg",
//       },
//     ],
//     seoTitle: "this is my seo title",
//     seoDescription: "this is my seo description",
//     seoAltTag: "this is my seo alt tag",
//     collection: "popular",
//   },
//   {
//     title: "Minimalist Wedding Invitation",
//     url: "https://i.pinimg.com/736x/9a/27/cf/9a27cfc9f56989c82180ddb3b2ad8fce.jpg",
//     items: [
//       {
//         description: "Canva template",
//         link: "https://www.thepapermillstore.com/superfine-white-paper-8-1-2-x-11-in-24-lb-writing-eggshell-500-per-ream.html",
//         photo:
//           "https://i.pcmag.com/imagery/reviews/05GF8sMpHfawiyKgGnrgf7X-8.fit_scale.size_760x427.v1665503374.jpg",
//       },
//       {
//         description: "Eggshell 5x7 stationery",
//         link: "https://www.thepapermillstore.com/superfine-white-paper-8-1-2-x-11-in-24-lb-writing-eggshell-500-per-ream.html",
//         photo:
//           "https://www.thepapermillstore.com/media/catalog/product/cache/c0d0765f17c5d924ac3de5571d1327fc/s/u/superfine-white-card-stock-eggshell.jpg",
//       },
//     ],
//     seoTitle: "this is my seo title",
//     seoDescription: "this is my seo description",
//     seoAltTag: "this is my seo alt tag",
//     collection: "popular",
//   },
// ];

const GlobalState = (props) => {
  const [navActive, setNavActive] = useState("Popular");
  const [activeCollection, setActiveCollection] = useState("popular");
  const [retrievedCollections, setRetrievedCollections] = useState({});
  const [allCollections, setAllCollections] = useState();

  return (
    <GlobalContext.Provider
      value={{
        navActive,
        setNavActive,
        activeCollection,
        setActiveCollection,
        retrievedCollections,
        setRetrievedCollections,
        allCollections,
        setAllCollections,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalState;
